import React, { useEffect, useState } from "react";
import { Layout } from "../utilityComponents/layout";
import { Container } from "../../helpers/container";
import link from "./../../assets/images/blogIcons/link.svg";
import facebook from "./../../assets/images/blogIcons/facebook.svg";
import instagram from "./../../assets/images/blogIcons/instagram.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import clock from "./../../assets/images/blogIcons/clock.svg";
import { strapiUrl } from "../../helpers/strapiUrl";
import { News } from "../utilityComponents/layoutComponents/news";
export default function BlogPage({ pageContext: { props, nodes } }: any) {
  const goToUrl = (url: string) => {
    location.href = url;
  };

  console.log(nodes);

  const [load, setLoad] = useState(false);
  useEffect(() => {
    setLoad(true);
  }, []);

  return (
    <Layout smallNavbar href="#IWT">
      <Container className="_blog">
        <div className="blog">
          <div className="blog__left">
            {load && window && (
              <CopyToClipboard text={String(window.location)}>
                <div className="blog__icon">
                  <img src={link} width="32px" />
                </div>
              </CopyToClipboard>
            )}

            {props.facebookLink && (
              <div
                className="blog__icon"
                onClick={() => goToUrl(props.facebookLink)}
              >
                <img src={facebook} width="32px" />
              </div>
            )}
            {props.instagramLink && (
              <div
                className="blog__icon"
                onClick={() => goToUrl(props.instagramLink)}
              >
                <img src={instagram} width="32px" />
              </div>
            )}
          </div>
          <div className="blog__center">
            <h1 className="blog__title">{props.title}</h1>
            <p className="blog__time">
              {`${moment(props.createdAt).format("L")} | `}
              <img src={clock} />

              {` ${props.timeToRead}`}
            </p>
            <img className="blog__image" src={strapiUrl(props.img.url)} />
            <p className="blog__short">{props.shortDescription}</p>
            <p className="blog__paragraph">{props.content}</p>
          </div>
          <div className="blog__right">
            <h1 className="blog__header">Popularne artykuły</h1>
            {nodes.map((n) => {
              return (
                <a className="blog__header__item" href={`/blog/${n.slug}`}>
                  <h3 className="blog__header__item--title">{n.title}</h3>
                  <div className="blog__header__item--paragraph">
                    {`${moment(props.createdAt).format("L")} | `}
                    <img src={clock} />
                    {` ${props.timeToRead}`}
                  </div>
                </a>
              );
            })}
          </div>
        </div>
        <News customTitle="Najnowsze artykuły" />
      </Container>
    </Layout>
  );
}
